.toggle {
  top:8px;
    --width: 33px;
    --height: calc(var(--width) / 3);
    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
  }

  .toggle input {
    display: none;
  }

  .toggle .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: #ccc;
    transition: all 0.4s ease-in-out;
  }

  .toggle .slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--height));
    height: calc(var(--height));
    border-radius: calc(var(--height) / 2);
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
  }

  .toggle input:checked+.slider {
    background-color: black;
  }

  .toggle input:checked+.slider::before {
    transform: translateX(calc(var(--width) - var(--height)));
  }

  .toggle .labels {
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-family: sans-serif;
    transition: all 0.4s ease-in-out;
  }

  .toggle .labels::after {
    /* content: attr(data-off); */
    position: absolute;
    right: 5px;
    color: #4d4d4d;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
  }

  .toggle .labels::before {
    /* content: attr(data-on); */
    position: absolute;
    left: 5px;
    color: #ffffff;
    opacity: 0;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
    transition: all 0.4s ease-in-out;
  }

  .toggle input:checked~.labels::after {
    opacity: 0;
  }

  .toggle input:checked~.labels::before {
    opacity: 1;
  }

.update-cate{
  text-transform: capitalize;
  /* background-color: aqua !important; */
}

.captlze{
  text-transform: capitalize;
}

.color-css{
  pointer-events:none;
}

.select2 input{
  box-shadow: none !important;
  min-height:0px !important;
  /* background-color: aqua !important; */
}

.variant-border{
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
}